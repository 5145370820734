.particle{
    background-image: linear-gradient(to bottom, #00061b,  #001357f3, #001357);
        // background: linear-gradient(60deg, #001359,  #014d7a, #001254f6, #000000);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    max-width: 100% !important;
    height: 110vh;
    overflow-y: hidden;
    overflow-x: none;
}

