@import "../../common/styles.scss";
.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer {
  width: 100%;
  max-width: 1400px;
  margin: 3% auto 1% auto;

  .innerFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $content_font;
    font-size: 1.2rem;
    font-weight: 500;
    color: rgb(207, 207, 207);
  }
}

.innerFooter p {
  text-align: center;
}

.footerLogo {
  height: 1em;
  margin-left: 0.5rem;
}
