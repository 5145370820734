/* // =================== shapes */
#shapes{
  opacity: .3;
}

.item5 {
  position: absolute;
  top: 100%;
  right: 15%;
}
/* // skills */
.item3 {
  position: absolute;
  top: 190%;
  left: 10%;
}

/* // project */
.item2 {
  position: absolute;
  top: 300%;
  right: 10%;
}


.item6 {
  position: absolute;
  bottom: -400%;
  right: 20%;

}
.item7 {
  position: absolute;
  bottom: -310%;
  right: 70%;
}
// social component
.item8 {
  position: relative;
  top: 10%;
  
}
@media (max-width: 850px){
  .shapes{
    width: 250px;
    opacity: .2;
  }
  #shapes{
    width: 250px;
    opacity: .2;
  }
}