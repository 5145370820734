@import "../../common/styles.scss";

.header {
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.headerContainer {
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  height: 89vh;
  width: 80%;
  align-items: center;
  color: rgb(255, 255, 255);
  justify-content: space-between;
}

.header__section {
  display: flex;
  flex-direction: column;
}

.headerIcons {
  list-style-type: none;
  display: flex;
  margin-bottom: 20px;
}
.headerText {
  color: $blue;
  font-family: $content_font;
  font-weight: 600;
  font-size: 1.3rem;

  //   &:hover {
  //     box-shadow: 0 0 20px  #9ecaed;
  //     border: 2px solid $blue;

  // }
}

.name {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

// .name > .title > .block {
//   width: 0%;
//   height: 5.8rem;
//   background: #ffb510;
//   position: absolute;
//   animation: mainBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
//   display: flex;
// }

.name > .title > h1 {
  width: 100%;
  font-size: 5.8rem;
  font-weight: 700;
  font-family: $content_font;
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  transition: 0.5 ease-in-out;
}

h1 > .fName {
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  display: inline-block;
  // animation: rubberBand 1.2s infinite;
}

.name > .title > h1 > .lName {
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  display: inline-block;
  // animation: rubberBand 1.1s infinite;
  color: #90a6fc;

  text-shadow: 0 1px 0 $purple, 0 2px 0 $purple, 0 3px 0 $purple,
    0 4px 0 $purple, 0 5px 0 $purple, 0 6px 0 transparent, 0 7px 0 transparent,
    0 8px 0 transparent, 0 9px 0 transparent, 5px 7px 7px $purple;
}

h1 > .fName:hover {
  color: #f0aa7b;
  // text-shadow: 0 1px 0 $purple,
  // 0 2px 0 $purple,
  // 0 3px 0 $purple,
  // 0 4px 0 $purple,
  // 0 5px 0 $purple,
  // 0 6px 0 transparent,
  // 0 7px 0 transparent,
  // 0 8px 0 transparent,
  // 0 9px 0 transparent,
  // 5px 7px 10px $purple;

  /* animation: rubberBand 5s infinite; */
  animation-name: rubberBand;
}
.name > .title > h1 > .lName:hover {
  color: #ffb510;
  // animation: rubberBand 5s infinite;
  animation-name: rubberBand;
}

// .name > .role > .block{
//   width: 0%;
//   height: 2.6rem;
//   background: #e91e63;
//   position: absolute;
//   animation: secBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
//   animation-delay: 2s;
//   display: flex;
// }

.name > .role > p {
  width: 100%;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 40px;
  font-family: $content_font;
  color: rgb(207, 207, 207);
  font-weight: 500;
  margin-bottom: 50px;
  // animation: secFadeIn 2s forwards;
  // animation-delay: 3.2s;
  // opacity: 0;

  span {
    color: $blue;
    display: inline-block;
    position: relative;
    line-height: 30px;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $blue;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
      text-decoration: underline $blue;
    }
  }
}

.headbtn {
  color: $blue;
  text-decoration: none;
  border: 2.2px solid #19cbe7;
  border-radius: 50px;
  padding: 12px 25px;
  font-weight: 700;
  font-size: large;
  transition: all 0.5s ease-out;
  font-family: $content_font;
  display: inline-block;
  &:hover {
    transform: scale(0.97);
    background-color: $hovblue;
    // box-shadow: 0 0 20px  #9ecaed;
    
  }
}

.btn-smart {
  background: rgb(255, 0, 53);
  padding: 13px 40px;
  border-radius: 50px;
}
.play {
  position: absolute;
  font-size: 25px !important;
  padding-top: 10px;
}

#songButton {
  position: fixed;
  right: 3.5%;
  bottom: 3%;
  font-size: 2.2rem;
  z-index: 99;
  transition: all 0.5s ease-in-out;
  animation:  rubberBand 2s infinite, red 0.5s infinite;
  cursor: pointer;
}

@keyframes red {
  from {
    text-shadow: 0 10px 15px red, 0 5px 25px red, 0 10px 30px red,
      0 5px 50px rgb(0, 255, 255), 0 20px 65px rgb(0, 255, 255),
      0 40px 80px rgb(0, 255, 255), 0 40px 100px rgb(0, 255, 255);
  }
}




@keyframes secBlock {
  0% {
    width: 0%;
    left: 10%;
  }
  50% {
    width: 35%;
    left: 10%;
  }
  100% {
    width: 0;
    left: 45%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d (1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@media (max-width: 850px) {
  .header {
    height: 110vh;
  }
  .name > .title > h1 {
    font-size: 3.4rem;
  }
  .name > .role > p {
    font-size: 1.3rem;
  }
  .headerContainer {
    width: 85%;
    // height: auto;
  }
  .btn-outline,
  .btn-smart {
    padding: 10px 25px;
    font-size: 1rem;
  }
}
