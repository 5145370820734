@import "../../common/styles.scss";
.about{
  width: 100%;
  height: fit-content;
  // background-image: linear-gradient(to bottom, #001357f6,  #000000);
  display: flex;   
}

.aboutContainer{
  justify-content: center;
  width: 100%;
  max-width: 1400px;
  margin: 3% auto;
  display: flex;
  flex-direction: column;
  height: fit-content;
  color: #f1f1f1;   
  z-index: 1;
}

.aboutHeading{
  margin-bottom: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutHeading > h1 {
  color: #f1f1f1;   
  margin-bottom: .5%;
  font-family: $header_font;
  font-weight: 300;
  font-size: 3.7rem;
  // text-shadow: 0px 0px 10px #19cbe7;
  cursor: pointer;
   
}
.aboutHeading > h1 > .colored{
    color: #95B0B6;
}

// .aboutHeading > h1 > .AName:hover {
//     color: #ffb510;
//     animation: rubberBand 5s infinite; 
//     animation-name: rubberBand;
//   }  
 


.aboutHeading > hr{
  width: 15%;
  height: 5px;
  background-color:  #90a6fc;
  border-radius: 5px;
  border: none;
}

.aboutContent{
  width: 100%;  
  display:flex;
  flex-flow: row wrap;
}

.aboutLeft{
  text-align: center;
  flex-basis: 40%;
  z-index: 99;
}

.about__img { 
  margin-top: 15%;
  margin-left: 6%;
  
    
.backgroungBox{
  margin: 0 auto;
  width: 50%;
  width: 19.6rem;
  height: 19.6rem;
  border-radius: 10% 0 50% 0;
  box-shadow: 5px 5px 10px #00cbddb6;
  // border: 2.5px solid $blue;
  // transform: scale(1.1);
  
}
// #90a6fc
  }
  .about__img .backgroungBox img {
    position: relative;
    top: -0.5rem;
    left: -0.5rem;
    width: 99%;
    border-radius: 10% 0 50% 0;
    // filter: brightness(.9);
    
    transition: all .4s ease-in-out;
    &:hover{
      filter: brightness(1);
      top: 0;
      left: 0;
}
}


  .moveImg {
    animation-name : mymove;
    animation-iteration-count : infinite;
    animation-direction : alternate;
    animation-duration : 3s;
    
  }
  


.aboutRight{
    padding: 3% 0;
    flex-basis: 60%;
    padding-right: 8%;
    padding-left: 0%;
}

.aboutRight > h1 {
font-family: $content_font;
  font-size: 2.3rem;
  color: #95B0B6;
  font-weight: 700;

}

.aboutRight > h1 > .wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}



.aboutRight > p{
  font-family: $content_font;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.7rem;
  margin-top: 3%;
  color: rgb(207, 207, 207);
  transition: 1sec linear;
  span{
    margin-right: 5px;
    color: $blue;
    display: inline-block;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $blue;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover:after{
      transform: scaleX(1);
      transform-origin: bottom left;
      text-decoration: underline $blue;
    }
  }
}

.info__contacts {
  margin-top: 10px;
}
.info__contacts .head{
  font-size: 1.15rem;
  font-weight: 700;
  color:  $blue;
  font-family: $content_font;
}
.info__contacts .info {
    font-family: $normal_font;
  font-size: 1.05rem;
  font-weight: 600;
  line-height: 35px;
  color: rgb(207, 207, 207);
  span{
    color: $blue;
    font-weight: 800;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 2% 0;
}
.items {
  margin: 1.8% 0;
  width: 45%;
}

// image updown move
@keyframes mymove {
    from {transform: translateY(0px);}
    to {transform: translateY(20px);}
  }

  // hover rubberband
  @keyframes rubberBand {
    from {
      transform: scale3d (1,1,1);
    }
  
    30% {
      transform: scale3d(1.25, .75,1);
    }
  
    40% {
      transform: scale3d(.75,1.25,1);
    }
  
    50% {
      transform: scale3d(1.15, .85,1);
    }
  
    65% {
      transform: scale3d(.95,1.05,1);
    }
  
    75% {
      transform: scale3d(1.05, .95,1);
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
  }
// wave hand
  @keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}


@media (max-width: 750px){

  .about{ 
    align-items: center;
    
  }

  .aboutContainer{
    margin: 40% auto 30% auto;
    width: 88%;
    
  }


   .aboutLeft{
  
       flex-basis: 100%; 
       .backgroungBox{
         transform: scale(.95);
         box-shadow: 0px 0px 20px #19cce7ab;
       }
   }

   .aboutHeading > h1 {
     font-size: 3rem;
     padding-right: 0;
   }

   .aboutRight{
      flex-basis: 100%;
      margin-top: 15%;
      line-height: 2rem;
      padding-right: 10%;
    p{
    width: 110%; 
    }
    .items{
      width: 40%;
      .info{
        width: 155%;
      }     
    }
    .info__contacts {
      width: 115%;     
    }
  }
}



