@import "../../common/styles.scss";
.socialContainer {
  margin: -20% auto auto auto;
  max-width: 1300px;
}
.socialContent {
  margin: 5% auto;
  .headings {
    margin-bottom: 4%;
    line-height: 3rem;

    h1,
    h2 {
      display: flex;
      justify-content: center;
      font-family: $content_font;
      // font-size: 2.3rem;
      color: #95b0b6;
      font-weight: 700;
    }
  }
}
.social-links {
  display: flex;
  margin-top: 1%;
  justify-content: center;
}

.social-links a {
  width: 4rem;
  height: 4rem;
  display: flex;
  margin: 0 30px;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  color: rgb(248, 248, 248);
  // border: 2.3px solid #19cce7de;
box-shadow: 5px 5px 10px #00cbddb6;
  border-radius: 50%;

  transition: transform 0.5s;

  .fab {
    transform: scale(1.1);
  }
}

.social-links a:hover {
  background: #19cce718;
  transform: translateY(-10px);
}

@media (max-width: 750px) {
  .socialContent {
    width: 80%;
    padding: 3% auto;

    .headings {
      line-height: 2.3rem;
      h1 {
        display: none;
      }
      h2 {
        margin: 60px auto;
        text-align: center;
      }
    }
  }
  .social-links a {
    width: 3.5rem;
    height: 3.5rem;
    margin: 3% 4%;
  }
}
