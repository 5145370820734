$background_color1: #00061b;
$box_shadow: 0 7px 10px rgba(0, 22, 70, 0.274);

$header_font: "Fredoka One", sans-serif;
$content_font: "Rubik", sans-serif;
$normal_font: "Open Sans", sans-serif;

$purple: #9964b0;
$red: rgb(255, 0, 53);
$orange: #f0aa7b;
$diffOr: #dae4f0;
$blue: #19cbe7;
$hovblue: #00dbdb17;
$gh: #000722f6;
$dblue: #0087ca;
$white: #f1f1f1;
$grey: rgb(207, 207, 207);
$blueGrey: #95b0b6;

.background_blue {
  font-size: 1.2rem;
  line-height: 1.8rem;
  width: 95%;
  padding: 3.5%;
  background: rgba(0, 58, 124, 0.541);
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 14px );
-webkit-backdrop-filter: blur( 14px );
border-radius: 0 10px 10px 0;
// border: 1px solid rgba( 255, 255, 255, 0.18 );
  box-shadow: -3px 0 #90a6fc;
}
.rotation{
   -webkit-animation:spin 10s linear infinite;
    -moz-animation:spin 10s linear infinite;
    animation:spin 30s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}