@import "../../common/styles.scss";

.navBar{  
    width: 100%;
    height: 10vh;
    position: sticky;
    top: 0%;
    display: flex;
    justify-content: center;
    background-color: #000824;
    z-index: 999;
    opacity: 0.87;
    // box-shadow: 0 5px 20px rgba(0, 22, 70, 0.1);
    


.navContainer{
    
    width: 80%;
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;


.leftContainer >  h1{
    padding: 1%;
    font-weight: 500;
    font-size: 2.2rem;
    font-family: $header_font;
    color: #ffff;
    width: 180px;
    background: linear-gradient(to right ,$blue, transparent);
    background-size: 7rem;
    background-repeat: no-repeat;
    border-left: 5px solid $blue;
}


.rightContainer > .rightContainer2 > .navItems > a{
    outline: none;
    color: rgba(255, 255, 255, 0.644);
    text-decoration: none;
    letter-spacing: 1px;
    font-weight: 550;
    padding: 0 15px;
    text-shadow: 0 0 1px rgba(255,255,255,0.3);
    font-size: 1.1rem;
    margin: 30px 0;
    transition: linear 0.5s;
    &:hover{
        color: rgb(255, 255, 255);
    }
}

.rightContainer > .rightContainer1 > .navItems > a{
    outline: none;
    color: rgba(255, 255, 255, 0.644);
    text-decoration: none;
    font-family: $content_font;
    letter-spacing: 1px;
    font-weight: 500;
    padding: 0 15px;
    text-shadow: 0 0 1px rgba(255,255,255,0.3);
    font-size: 1.1rem;
    transition: linear 0.5s;

    &:hover{
        color: rgb(255, 255, 255);
    }
}

    

.rbutton{outline: none;
border: none;
color: $blue;
text-decoration: none;
background-color: #19cce713;
border: 2.2px solid $blue;
border-radius: 19px;
padding: 0.5rem 1.4rem;
font-weight: 600;
font-size: 1rem;
transition: all .5s ease-out;
font-family: $content_font;
cursor: pointer;
&:hover{
    box-shadow: 0 10px 20px rgba(17, 70, 184, 0.274);
    transform: scale(.97);
    background-color: #00dbdb2d;
    }

    }
}
}
  .toggle {
    background:rgb(0, 106, 155);
    color: #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: none;
  }


@media(max-width: 950px){
    .navBar > .navContainer > .rightContainer > .rightContainer2 {
        width: 100%;
        
    }
    .navBar > .navContainer > .rightContainer > .rightContainer1 > .navItems{
        display: none;
    }


    .navBar > .navContainer > .rightContainer > .rightContainer2 > .navItems{
        display: flex;
        flex-direction: column;
        padding: 40px;
        background-color: #00092b;
        width: 100%;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        font-family: $content_font;
        
    }

    .toggle {
        display: flex;
        z-index: 1;
    }
    
}
