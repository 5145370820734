*{
  padding: 0%;
  margin: 0;
  box-sizing: border-box;  
}


/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #9fb1f8;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #90a6fc;
  border-radius: 10px;
}



@media (max-width: 850px){
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 102, 255, 0.726);
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0087ca;
  border-radius: 10px;
}

}

/* html,
body {

       cursor:  none;      }   */
    
/* .wrapper{
  width: 100%;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  
} */

.cursor-dot,
.cursor-dot-outline {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out,
                transform 0.3s ease-in-out;
}

.cursor-dot { 
    width: 8px;
    height: 8px;
    background-color: rgb(112, 0, 242);
}

.cursor-dot-outline {
    width: 40px;
    height: 40px;
    background-color: rgba(112, 0, 242, 0.5);
}
/* Pre loader */
.loader {
  position: absolute;
  top: calc(50vh - 75px);
  left: calc(50vw - 75px);
  width: 150px;
  height: 150px;
  animation: loading 2s infinite ease-out;
  margin: auto;
  border-radius: 50%;
  background-color: red;
}

.loader:before{
  animation-delay: .5s;
}
@keyframes loading {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(8);
    opacity: 0;
  }
}
