@import "../../common/styles.scss";


.skillLeft{
  margin: 5% 0 5% 7%;
}

.skillContainer {
  
  margin: 3% 3% 0 0;
  color: white;
  display: flex;
}
.techh2{
  font-family: $header_font;
  position: relative;
  text-decoration: none;
  font-size: 2.4rem;
  font-weight: 600;
  background: -webkit-linear-gradient(#0087ca,#19cbe7);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.skillRight {
  opacity: 1;
  color: white;
}

@media only screen and (max-width: 800px) {
  .skillContainer {
    flex-direction: column;
  }
  #skillCanvas > canvas {
    width: 100% !important;
    height: 40vh !important;
  }
  .skillRight {
    width: 100% !important;
  }

}