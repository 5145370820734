  /* ============= */

  .night {
    position: absolute;
    width: 100%;
    top: 25%;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
  }
  
  .shooting_star {
    position: absolute;
    left: 40%;
    top: 50%;
    height: 2px;
    background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
    /* border-radius: 999px; */
    -webkit-filter: drop-shadow(0 0 6px #699bff);
            filter: drop-shadow(0 0 6px #699bff);
    -webkit-animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
            animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
  }
  .shooting_star::before, .shooting_star::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
    -webkit-transform: translateX(50%) rotateZ(45deg);
            transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    -webkit-animation: shining 3000ms ease-in-out infinite;
            animation: shining 3000ms ease-in-out infinite;
  }
  .shooting_star::after {
    -webkit-transform: translateX(50%) rotateZ(-45deg);
            transform: translateX(50%) rotateZ(-45deg);
  }
  .shooting_star:nth-child(1) {
    top: calc(50% - 117px);
    left: calc(50% - 31px);
    -webkit-animation-delay: 928ms;
            animation-delay: 928ms;
  }
  .shooting_star:nth-child(1)::before, .shooting_star:nth-child(1)::after, .shooting_star:nth-child(1)::after {
    -webkit-animation-delay: 928ms;
            animation-delay: 928ms;
  }
  .shooting_star:nth-child(2) {
    top: calc(50% - -121px);
    left: calc(50% - 248px);
    -webkit-animation-delay: 4129ms;
            animation-delay: 4129ms;
  }
  .shooting_star:nth-child(2)::before, .shooting_star:nth-child(2)::after, .shooting_star:nth-child(2)::after {
    -webkit-animation-delay: 4129ms;
            animation-delay: 4129ms;
  }
  .shooting_star:nth-child(3) {
    top: calc(50% - -110px);
    left: calc(50% - 9px);
    -webkit-animation-delay: 9079ms;
            animation-delay: 9079ms;
  }
  .shooting_star:nth-child(3)::before, .shooting_star:nth-child(3)::after, .shooting_star:nth-child(3)::after {
    -webkit-animation-delay: 9079ms;
            animation-delay: 9079ms;
  }
  .shooting_star:nth-child(4) {
    top: calc(50% - -74px);
    left: calc(50% - 279px);
    -webkit-animation-delay: 6878ms;
            animation-delay: 6878ms;
  }
  .shooting_star:nth-child(4)::before, .shooting_star:nth-child(4)::after, .shooting_star:nth-child(4)::after {
    -webkit-animation-delay: 6878ms;
            animation-delay: 6878ms;
  }
  .shooting_star:nth-child(5) {
    top: calc(50% - 88px);
    left: calc(50% - 122px);
    -webkit-animation-delay: 8413ms;
            animation-delay: 8413ms;
  }
  .shooting_star:nth-child(5)::before, .shooting_star:nth-child(5)::after, .shooting_star:nth-child(5)::after {
    -webkit-animation-delay: 8413ms;
            animation-delay: 8413ms;
  }
  .shooting_star:nth-child(6) {
    top: calc(50% - -16px);
    left: calc(50% - 231px);
    -webkit-animation-delay: 6531ms;
            animation-delay: 6531ms;
  }
  .shooting_star:nth-child(6)::before, .shooting_star:nth-child(6)::after, .shooting_star:nth-child(6)::after {
    -webkit-animation-delay: 6531ms;
            animation-delay: 6531ms;
  }
  .shooting_star:nth-child(7) {
    top: calc(50% - -118px);
    left: calc(50% - 152px);
    -webkit-animation-delay: 6905ms;
            animation-delay: 6905ms;
  }
  .shooting_star:nth-child(7)::before, .shooting_star:nth-child(7)::after, .shooting_star:nth-child(7)::after {
    -webkit-animation-delay: 6905ms;
            animation-delay: 6905ms;
  }
  .shooting_star:nth-child(8) {
    top: calc(50% - -63px);
    left: calc(50% - 123px);
    -webkit-animation-delay: 3771ms;
            animation-delay: 3771ms;
  }
  .shooting_star:nth-child(8)::before, .shooting_star:nth-child(8)::after, .shooting_star:nth-child(8)::after {
    -webkit-animation-delay: 3771ms;
            animation-delay: 3771ms;
  }
  .shooting_star:nth-child(9) {
    top: calc(50% - -125px);
    left: calc(50% - 1px);
    -webkit-animation-delay: 9378ms;
            animation-delay: 9378ms;
  }
  .shooting_star:nth-child(9)::before, .shooting_star:nth-child(9)::after, .shooting_star:nth-child(9)::after {
    -webkit-animation-delay: 9378ms;
            animation-delay: 9378ms;
  }
  .shooting_star:nth-child(10) {
    top: calc(50% - -179px);
    left: calc(50% - 112px);
    -webkit-animation-delay: 9042ms;
            animation-delay: 9042ms;
  }
  .shooting_star:nth-child(10)::before, .shooting_star:nth-child(10)::after, .shooting_star:nth-child(10)::after {
    -webkit-animation-delay: 9042ms;
            animation-delay: 9042ms;
  }
  .shooting_star:nth-child(11) {
    top: calc(50% - -140px);
    left: calc(50% - 285px);
    -webkit-animation-delay: 348ms;
            animation-delay: 348ms;
  }
  .shooting_star:nth-child(11)::before, .shooting_star:nth-child(11)::after, .shooting_star:nth-child(11)::after {
    -webkit-animation-delay: 348ms;
            animation-delay: 348ms;
  }
  .shooting_star:nth-child(12) {
    top: calc(50% - 163px);
    left: calc(50% - 78px);
    -webkit-animation-delay: 8377ms;
            animation-delay: 8377ms;
  }
  .shooting_star:nth-child(12)::before, .shooting_star:nth-child(12)::after, .shooting_star:nth-child(12)::after {
    -webkit-animation-delay: 8377ms;
            animation-delay: 8377ms;
  }
  .shooting_star:nth-child(13) {
    top: calc(50% - -17px);
    left: calc(50% - 233px);
    -webkit-animation-delay: 5411ms;
            animation-delay: 5411ms;
  }
  .shooting_star:nth-child(13)::before, .shooting_star:nth-child(13)::after, .shooting_star:nth-child(13)::after {
    -webkit-animation-delay: 5411ms;
            animation-delay: 5411ms;
  }
  .shooting_star:nth-child(14) {
    top: calc(50% - 53px);
    left: calc(50% - 185px);
    -webkit-animation-delay: 736ms;
            animation-delay: 736ms;
  }
  .shooting_star:nth-child(14)::before, .shooting_star:nth-child(14)::after, .shooting_star:nth-child(14)::after {
    -webkit-animation-delay: 736ms;
            animation-delay: 736ms;
  }
  .shooting_star:nth-child(15) {
    top: calc(50% - 168px);
    left: calc(50% - 117px);
    -webkit-animation-delay: 8557ms;
            animation-delay: 8557ms;
  }
  .shooting_star:nth-child(15)::before, .shooting_star:nth-child(15)::after, .shooting_star:nth-child(15)::after {
    -webkit-animation-delay: 8557ms;
            animation-delay: 8557ms;
  }
  .shooting_star:nth-child(16) {
    top: calc(50% - -132px);
    left: calc(50% - 282px);
    -webkit-animation-delay: 4227ms;
            animation-delay: 4227ms;
  }
  .shooting_star:nth-child(16)::before, .shooting_star:nth-child(16)::after, .shooting_star:nth-child(16)::after {
    -webkit-animation-delay: 4227ms;
            animation-delay: 4227ms;
  }
  .shooting_star:nth-child(17) {
    top: calc(50% - -24px);
    left: calc(50% - 166px);
    -webkit-animation-delay: 4370ms;
            animation-delay: 4370ms;
  }
  .shooting_star:nth-child(17)::before, .shooting_star:nth-child(17)::after, .shooting_star:nth-child(17)::after {
    -webkit-animation-delay: 4370ms;
            animation-delay: 4370ms;
  }
  .shooting_star:nth-child(18) {
    top: calc(50% - 136px);
    left: calc(50% - 136px);
    -webkit-animation-delay: 378ms;
            animation-delay: 378ms;
  }
  .shooting_star:nth-child(18)::before, .shooting_star:nth-child(18)::after, .shooting_star:nth-child(18)::after {
    -webkit-animation-delay: 378ms;
            animation-delay: 378ms;
  }
  .shooting_star:nth-child(19) {
    top: calc(50% - -25px);
    left: calc(50% - 5px);
    -webkit-animation-delay: 3731ms;
            animation-delay: 3731ms;
  }
  .shooting_star:nth-child(19)::before, .shooting_star:nth-child(19)::after, .shooting_star:nth-child(19)::after {
    -webkit-animation-delay: 3731ms;
            animation-delay: 3731ms;
  }
  .shooting_star:nth-child(20) {
    top: calc(50% - 124px);
    left: calc(50% - 103px);
    -webkit-animation-delay: 8600ms;
            animation-delay: 8600ms;
  }
  .shooting_star:nth-child(20)::before, .shooting_star:nth-child(20)::after, .shooting_star:nth-child(20)::after {
    -webkit-animation-delay: 8600ms;
            animation-delay: 8600ms;
  }


  
  
  
  

  @-webkit-keyframes tail {
    0% {
      width: 0;
    }
    30% {
      width: 100px;
    }
    100% {
      width: 0;
    }
  }
  
  @keyframes tail {
    0% {
      width: 0;
    }
    30% {
      width: 100px;
    }
    100% {
      width: 0;
    }
  }
  @-webkit-keyframes shining {
    0% {
      width: 0;
    }
    50% {
      width: 30px;
    }
    100% {
      width: 0;
    }
  }
  @keyframes shining {
    0% {
      width: 0;
    }
    50% {
      width: 30px;
    }
    100% {
      width: 0;
    }
  }
  @-webkit-keyframes shooting {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(300px);
              transform: translateX(300px);
    }
  }
  @keyframes shooting {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(300px);
              transform: translateX(300px);
    }
  }


  @media (max-width: 800px){
.night{
  width: 50%;
    }

    @keyframes shooting {
      0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
      }
      100% {
        -webkit-transform: translateX(10px);
                transform: translateX(90px);
      }
    }
  }
