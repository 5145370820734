@import "../../common/styles.scss";



// #imgOverlay{
//     background-color: #00badb8a;
//     clear: float;
//     :hover{
//         background-color: #ffffff8a;
//     }
// }
.proImage{

    // box-shadow: 2px 2px 13px #19cce7d2;
    background-color: #19cce775;
    
    img{
        opacity: 0.7;
        transition:  .3s linear ;
        &:hover{
            opacity: 1;
        }
    }   
}
    


.outerContent{
    .proCont{
                h3{
            color: $blue;
            font-family: $normal_font;
            font-size: .9rem;
        }
        h2{
            color: #f1f1f1;
            font-family: $content_font;
            font-size: 2rem;
            margin-top: 1%;
            margin-bottom: 2%;
        }
        .innerContent{
            background: rgba(0, 58, 124, 0.541);
            box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
            backdrop-filter: blur( 14px );
            -webkit-backdrop-filter: blur( 14px );
            
            // border: 1px solid rgba( 255, 255, 255, 0.18 );
            padding: 3%;
            margin-bottom: 3%;
            // box-shadow: -3px 0 #90a6fc;
            width: 80%;

            
            p{
                color: $grey;
                font-family: $normal_font;
                font-weight: 600;


            }

            
        }

        h4{
            color: $grey;
            font-family: $normal_font;
            font-weight: 600;
            font-size: .9rem;
       
        }
        a{
            .icons{

                color: $grey  !important;
                margin: 3% 0 0;
                transition: .3s ease-in-out;
                &:hover{
                    color: #f1f1f1 !important;
                }
            }
            .centIcon{
                margin: auto 2%;
            }
           }
    }
}

@media (max-width: 850px){

     .aboutHeading > h1 {
       font-size: 2rem;
       padding-right: 0;
       display: flex;
       justify-content: center;
       
     }
  
     .projectContent{
         margin-top: 13% !important;
         display:flex !important;
         line-height: 1.7rem;
        
        

         .proImage{
             position: relative !important;
             right: 0% !important;
             left: 0% !important;
             flex-basis: 100% !important;
             height: 225px !important; 

         }
         .outerContent{
            text-align: left !important;
            position: relative !important;
            right: 0% !important;
            left: 0% !important;
            
            flex-basis: 100% !important;

         
         .proCont{
    

           
            .innerContent{
                
                position: relative !important;
                right: 0% !important;
                left: 0% !important;
                line-height: 1.5rem;
                width: 100% !important;
            }
         }}
     }

     .outerMob{
         position: relative !important;
         bottom: -14.8rem !important; 
     }
     .imagMob{
        position: relative !important;
        top: -18.7rem !important; 
     }
   
  
  
  }

  .proLinks{

  
    margin-right: 5px;
    color: $blue;
    display: inline-block;
    position: relative;
    text-decoration: none;
    font-family: $content_font;
    font-weight: 500;
    font-size: 1rem;

    &:after{
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $blue;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover:after{
      transform: scaleX(1);
      transform-origin: bottom left;
      text-decoration: underline $blue;
    }
  
  }