@import "../../common/styles.scss";

.moveArrow {
  position: absolute;
  left: 60%;
  top: 27%;
  animation-name: mymove;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2s;
}

model-viewer {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  outline: none;
  --poster-color: transparent;
  transition: all 0.5s ease-in-out;
  animation: entrance 4s;
  box-shadow: 5px 5px 10px #00cbddb6;
  &:hover {
    transform: scale(1.05);
  }
}

:not(:defined) > * {
  display: none;
}
.Hotspot,
.Hotspot1 {
  background: #fff;
  border-radius: 32px;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
  height: 5px;
  position: relative;
  transition: opacity 0.3s;
  width: 10px;
}

.Hotspot:not,
Hotspot1:not([data-visible]) {
  background: transparent;
  border: 4px solid #fff;
  box-shadow: none;
  height: 10px;
  pointer-events: none;
  width: 20px;
}

.Hotspot:focus {
  border: 4px solid rgb(0, 128, 200);
  height: 28px;
  outline: none;
  width: 28px;

  &::after {
    content: "Soon!";
    color: #fff;
    position: relative;
    top: 30px;
  }
}

.Hotspot1:focus {
  border: 4px solid rgb(73, 200, 0);
  height: 28px;
  outline: none;
  width: 28px;

  &::after {
    content: "Done!";
    color: #fff;
    position: relative;
    top: 30px;
  }
}

.Hotspot1 > * {
  opacity: 1;
  transform: translateY(-50%);
}
.Hotspot > * {
  opacity: 1;
  transform: translateY(-50%);
}

.HotspotAnnotation {
  background: rgba(255, 255, 255, 0.575);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 10px;
  font-weight: 700;
  left: calc(100% + 0.5em);
  max-width: 100px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  top: 50%;
  width: max-content;
}

.Hotspot1:not([data-visible]) > * {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-50% + 4px));
  transition: transform 0.3s, opacity 0.3s;
}
.Hotspot:not([data-visible]) > * {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-50% + 4px));
  transition: transform 0.3s, opacity 0.3s;
}

#ar-button {
  background-image: url(ar_icon.png);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 12px 50%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  bottom: 16px;
  padding: 0px 16px 0px 40px;
  font-family: Roboto Regular, Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #4285f4;
  height: 36px;
  line-height: 36px;
  border-radius: 18px;
  border: 1px solid #dadce0;
}

#ar-button:active {
  background-color: #e8eaed;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}

@keyframes circle {
  from {
    transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

@keyframes elongate {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(-100px);
  }
}

model-viewer > #ar-prompt {
  position: absolute;
  left: 50%;
  bottom: 60px;
  animation: elongate 2s infinite ease-in-out alternate;
  display: none;
}

model-viewer[ar-status="session-started"] > #ar-prompt {
  display: block;
}

model-viewer > #ar-prompt > img {
  animation: circle 4s linear infinite;
}

@keyframes entrance {
  0% {
    visibility: hidden;
  }
  85% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}


@keyframes mymove {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(25px);
  }
}

@media (max-width: 1000px) {
  model-viewer {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    opacity: 0.85;
  }

  .moveArrow {
    z-index: -1;
    opacity: 0.5;
    left: 10%;
    top: 30%;
  }
}
