@import "../styles.scss";

.Underlined {
    span{   
        color: $blue;
        display: inline-block;
        position: relative;
        &:after{
          content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $blue;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
        }

        &:hover:after{
          transform: scaleX(1);
          transform-origin: bottom left;
          text-decoration: underline $blue;
        }

      }
}